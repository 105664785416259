import React from 'react'

import App from '../src/App'
import getIntroPageServerSideProps from '../src/getIntroPageServerSideProps'

export default function CodeIntroPage(props) {
  return (
    <App {...props} />
  )
}

export const getServerSideProps = getIntroPageServerSideProps
